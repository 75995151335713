import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faHandHoldingHeart,
  faCloudBolt,
  faAppleWhole,
  faPodcast,
  faPersonFallingBurst,
  faHandsBubbles,
  faMicrochip,
  faCrosshairs,
  faExplosion,
} from "@fortawesome/free-solid-svg-icons";
import "../utils/App.css";
import SearchComponent from "../SearchComponent";

function Home() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate("/category", { state: { category } });
  };

  return (
    <motion.div
      initial={{ x: 100}}
      animate={{ x: 0}}
      transition={{ duration: 0.5
      }}
      className="col-lg-10 custom"
    >
      <div className="row col-sm-11 col-lg-11 justify-content-center">
        <img className="large-icon" src="/img/icon-top.png" />
        <SearchComponent />
        <div className="category-row row gap-2">
          <div
            className="category-block"
            onClick={() => handleCategoryClick("animais")}
          >
            <div className="text-block">
              <FontAwesomeIcon
                icon={faPaw}
                style={{ color: "rgb(255, 156, 102)" }}
              />
              Animais
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("impactos")}
          >
            <div className="text-block">
              <FontAwesomeIcon
                icon={faCrosshairs}
                style={{ color: "#ffffff" }}
              />
              Impactos
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("clima")}
          >
            <div className="text-block">
              <FontAwesomeIcon icon={faCloudBolt} style={{ color: "#fffff" }} />
              Clima
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("ação")}
          >
            <div className="text-block">
              <FontAwesomeIcon icon={faExplosion} />
              Ação
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("sons")}
          >
            <div className="text-block">
              <FontAwesomeIcon icon={faPodcast} />
              Sons
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("estado humano")}
          >
            <div className="text-block">
              <FontAwesomeIcon
                icon={faPersonFallingBurst}
                style={{ color: "rgb(0, 255, 255)" }}
              />
              Estado humano
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("toque")}
          >
            <div className="text-block">
              <FontAwesomeIcon
                icon={faHandsBubbles}
                style={{ color: "#ffffff" }}
              />
              Toque
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("eletrônico")}
          >
            <div className="text-block">
              <FontAwesomeIcon
                icon={faMicrochip}
                style={{ color: "rgb(232, 232, 232)" }}
              />
              Eletrônico
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("emoção")}
          >
            <div className="text-block">
              <FontAwesomeIcon icon={faHandHoldingHeart} />
              Emoção
            </div>
          </div>
          <div
            className="category-block"
            onClick={() => handleCategoryClick("comer beber")}
          >
            <div className="text-block">
              <FontAwesomeIcon icon={faAppleWhole} />
              Comer Beber
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
