import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function Onomatopeias() {
  const { tag } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`http://192.168.0.2:8000/onomatopeiasAPI?tag=${tag}`)
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados:", error);
        setError(error);
      });
  }, [tag]);

  if (error) {
    return <div>Ocorreu um erro ao buscar os dados: {error.message}</div>;
  }

  return (
    <div className="col-lg-10 custom">
      <div className="row col-sm-11 col-lg-11">
        <div className="tag-title">#{tag}</div>
        {Array.isArray(data) ? (
          data.map((onomatopeias, index) => <div key={index}>teste</div>)
        ) : (
          <div>Não há dados para exibir</div>
        )}
      </div>
    </div>
  );
}

export default Onomatopeias;
