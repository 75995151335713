import { motion } from "framer-motion";

function About() {
  return (
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
      className="col-lg-10 custom"
    >
      <div className="row">Olá, eu sou o Marcus!</div>
    </motion.div>
  );
}

export default About;
