import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import "./utils/App.css";

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchSuggestions = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://admin.onomatopeias.com/shortAPI?search=${encodeURIComponent(
            searchTerm
          )}`,
          { cancelToken: source.token }
        );

        if (response.status !== 200) {
          throw new Error("Error fetching suggestions. Please try again.");
        }

        const { onomatopeias } = response.data;
        if (onomatopeias.length === 0) {
          setError("No onomatopoeias found.");
        } else {
          setSuggestions(onomatopeias);
          setError(null);
        }
        setError(null);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching suggestions:", error.message);
          setSuggestions([]);
          setError(
            error.message || "Error fetching suggestions. Please try again."
          );
        }
      } finally {
        setLoading(false);
      }
    };

    if (searchTerm.trim() === "") {
      setSuggestions([]);
      source.cancel("Operation canceled by the user.");
      return;
    }

    fetchSuggestions();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [searchTerm]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const wordList = [
    "digite uma tag",
    "Copilot",
    "inteligente",
    "criativo",
    "amigável",
    "versátil",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(wordList[currentIndex]);
  const [typing, setTyping] = useState(true); // Controla se estamos digitando ou apagando

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (typing) {
        // Estamos digitando
        if (currentWord.length < wordList[currentIndex].length) {
          setCurrentWord(
            wordList[currentIndex].substring(0, currentWord.length + 1)
          );
        } else {
          setTyping(false); // Troca para apagar a palavra
        }
      } else {
        // Estamos apagando
        if (currentWord.length > 0) {
          setCurrentWord(currentWord.slice(0, -1));
        } else {
          setTyping(true); // Troca para digitar a próxima palavra
          const nextIndex = (currentIndex + 1) % wordList.length;
          setCurrentIndex(nextIndex);
        }
      }
    }, 170); // Ajuste o intervalo conforme desejado (200 ms para um efeito mais rápido)

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentIndex, currentWord, typing]);

  return (
    <div className="col-md-12">
      <form className="search-form">
        <input
          id="search"
          className={`${
            loading
              ? "search-input"
              : error
              ? "search-input error"
              : "search-input"
          } ${searchTerm.trim() !== "" ? "has-content" : ""}`}
          type="search"
          placeholder={"#" + currentWord}
          value={searchTerm}
          onChange={handleInputChange}
          autoComplete="off"
        />

        <span className="material-symbols-outlined search-button-top">
          search
        </span>
        {suggestions.length > 0 && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="suggestions-list"
          >
            {suggestions.slice(0, 7).map((onomatopeia) => (
              <li key={onomatopeia.id}>{onomatopeia.name}</li>
            ))}
          </motion.ul>
        )}
      </form>
    </div>
  );
};

export default SearchComponent;
