import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../utils/App.css";

const Category = () => {
  const location = useLocation();
  const category = location.state.category;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [onomatopeias, setOnomatopeias] = useState([]);

  function normalizeCategoryForTitle(category) {
    return category.replace(/^(.)/, (match, firstLetter) =>
      firstLetter.toUpperCase()
    );
  }

  function normalizeCategory(category) {
    return category
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "_")
      .toLowerCase();
  }

  useEffect(() => {
    const fetchTags = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://admin.onomatopeias.com/category?category=${encodeURIComponent(
            category
          )}`
        );

        if (response.status !== 200) {
          throw new Error(
            "Erro ao buscar as tags, tente novamente mais tarde."
          );
        }
        setOnomatopeias(response.data.onomatopeias);
        setError(null);
      } catch (error) {
        console.error("Erro ao buscar as tags:", error.message);
        setOnomatopeias([]);
        setError(
          error.message || "Erro ao buscar as tags, tente novamente mais tarde."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, [category]);

  function Modal(onomatopeia) {
    useEffect(() => {
      const FetchOnomatopeia = async () => {
        try {
          const response = await axios.get(
            "https://onomatopeias.com/onomatopeia",
            {
              params: {
                onomatopeia: onomatopeia, // Substitua pela onomatopeia desejada
              },
            }
          );
          console.log(response.data);
        } catch (error) {
          console.error(
            "Erro ao buscar a onomatopeia ou ela não existe:",
            error.message
          );
        }
      };

      FetchOnomatopeia();
    }, [onomatopeia]);
  };

  const [alreadyCopied, setAlreadyCopied] = useState(false);

  const handleCopyClick = async (event, onomatopeia) => {
    try {
      const target = event.target;
      const sonOriginal = target.querySelector(".name");
      const svg = target.querySelector("svg.svg-inline--fa.fa-copy");
      const path = target.querySelector("path");
      const newSon = document.createElement("span");
      newSon.textContent = "Copiado!";
      console.log(svg);
      console.log(path);
      console.log(event.target);

      if (!alreadyCopied && target == svg || target == path) {
        sonOriginal.style.display = "none";
        svg.style.display = "none";

        target.appendChild(newSon);

        setAlreadyCopied(true);

        await navigator.clipboard.writeText(onomatopeia);

        target.classList.add("copied");

        setTimeout(() => {
          target.removeChild(newSon);
          target.classList.remove("copied");
          setAlreadyCopied(false);
          sonOriginal.style.display = "block";
          svg.style.display = "block";
        }, 1500);
      }
    } catch (error) {
      console.error("Erro ao copiar texto:", error);
    }
  };

  return (
    <div className="col-lg-10 custom">
      {loading ? (
        <div>Carregando...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
          className="row col-sm-11 col-lg-11"
        >
          <div
            className={`col-md-12 category-title ${normalizeCategory(
              category
            )}`}
          >
            <Link to="/" className={`${normalizeCategory(category)}`}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            {normalizeCategoryForTitle(category)}
          </div>
          {onomatopeias.map((onomatopeia, index) => (
            <div key={index} className="col-6 col-sm-4 col-md-3">
              <div
                className={`${normalizeCategory(category)}`}
                onClick={(event) => handleCopyClick(event, onomatopeia.name)}
              >
                <div className="name">{onomatopeia.name}</div>
                <FontAwesomeIcon
                  icon={faCopy}
                />
              </div>
            </div>
          ))}
        </motion.div>
      )}
      <div className="modal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
