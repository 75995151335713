import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Menu from "./utils/Menu";
import Tags from "./pages/Tags";
import Category from "./pages/Category";
import Onomatopeias from "./pages/Onomatopeias";

function Navigation() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("home");

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    setActivePage(
      currentPath === "" || currentPath === "category" ? "home" : currentPath
    );
  }, [location]);

  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link
          className={`nav-link ${activePage === "home" ? "active" : ""}`}
          to="/"
        >
          HOME
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${activePage === "about" ? "active" : ""}`}
          to="/about"
        >
          ABOUT
        </Link>
      </li>
      <li className="nav-item">
      <Link
          className={`nav-link ${activePage === "tags" ? "active" : ""}`}
          to="/tags"
        >
          TAGS
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link">
          WORDS
        </Link>
      </li>
    </ul>
  );
}

function App() {
  const [showH1, setShowH1] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const larguraDaJanela = window.innerWidth;

      if (larguraDaJanela < 590 && showH1) {
        setShowH1(false);
      } else if (larguraDaJanela >= 590 && !showH1) {
        setShowH1(true);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showH1]);

  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <div
            className="navbar navbar-expand-lg navbar-custom col-lg-2"
            data-bs-theme="dark"
          >
            <div className="container-fluid">
              <a className="col-sm-4 col-lg-12 logo-box fs-3">
                <img src="/img/logo.png" />
              </a>
              <div className="col-sm-8 col-lg-12 navbar-collapse-custom">
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar"
                  aria-controls="navbar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="navbar">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <React.Fragment>
                  <Menu />
                  <Home />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <React.Fragment>
                  <About />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/tags"
              element={
                <React.Fragment>
                  <Tags />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/category"
              element={
                <React.Fragment>
                  <Category />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/tags/:tag"
              element={
                <React.Fragment>
                  <Onomatopeias />
                </React.Fragment>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
